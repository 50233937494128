<template>
  <div class="charterup">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '@/scss/charterup/main';
</style>
